import React from 'react';
import { maniaNotes } from '../tableNotes'

const ManiaRow = ({ advanceReport }) => {
  const { guardian, child } = advanceReport.results
  const childScore = () => {
    const { child } = advanceReport.questions
    const scores = child.filter((q) => q.question_name === 'mania_history_1' && q.answer_text !== "No one that I know of")
    if (!scores.length) return null

    // Show "You" as "Self" (report only, not in survey)
    const adolescentAnswer = scores.map((score) => score.answer_text === 'You' ? 'Self' : score.answer_text).join(', ')
    return <p>Adolescent reports family history in {adolescentAnswer}</p>
  }

  const guardianScore = () => {
    const { guardian } = advanceReport.questions
    const scores = guardian.filter((q) => q.question_name === 'mania_history' && q.answer_text !== "No one that I know of")
    if (!scores.length) return null

    // Show "You" as "Self" (report only, not in survey)
    const parentAnswer = scores.map((score) => score.answer_text === 'You' ? 'Self' : score.answer_text).join(', ')
    return <p>Parent reports family history in {parentAnswer}</p>
  }

  const childConcern = child.cmrs ? `CMRS = ${child.cmrs}` : "No answer given"
  const guardianConcern = guardian.cmrs ? `CMRS = ${guardian.cmrs}` : "No answer given"
  
  return (
    <div className="table-row border-t-2 border-b-2 border-sw-lightest-gray align-top" role="row">
      <div className="print-symptom table-cell font-bold text-lg text-sw-dark-blue py-3 pr-2" role="rowheader">
        Mania
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {childConcern}
        {childScore()}
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {guardianConcern}
        {guardianScore()}
      </div>
      <div className="summary-notes-col table-cell align-top" role="gridcell">
        {maniaNotes(advanceReport)}
      </div>
    </div>
  )
}

export default ManiaRow