import React from 'react'


const FooterInformation = () => (
  <div className="footer-report container">
    <div className="text-base leading-normal pb-10">
      <p>
        Find information on treatment guidelines, referral sources, and item responses in Screening Item Level Report. Remember if no safety risk, can schedule a follow-up visit.
      </p>
    </div>
  </div>
)

export default FooterInformation