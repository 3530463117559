import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
  advancedReport,
  resetReport,
  report
} from '../../../actions/questionActions';
import {
  addToHistory,
  removeLastHistoryItem
} from '../../../actions/historyActions';
import ItemLevelReport from '../DetailedReport/ItemLevelReport';
import Barriers from './Barriers'
import StickyHeader from './StickyHeader';
import SummaryTable from './SummaryTable';
import Loading from './Loading';
import Preferences from './Preferences'
import FooterInformation from './Footer';
import { getId } from '../../../util/helperFunctions';
import '../../../assets/scss/_reports.scss';
import MainHeader from './NavHeaders/MainHeader';
import BasicItemHeader from './NavHeaders/BasicItemHeader';
import PreferencesHeader from './NavHeaders/PreferencesHeader';
import ClinicalCareHeader from './NavHeaders/ClinicalCareHeader';
import PatientHandoutHeader from './NavHeaders/PatientHandoutHeader';
import Counseling from '../Handouts/Counseling';
import ClinicalCareResources from '../Handouts/ClinicalCareResources'
import PatientHandout from '../Handouts/PatientHandout';
import AlertBlock from './AlertBlock';
import TreatmentCardsRow from './TreatmentCardsRow';
import { FULL, SUMMARY, HANDOUT, ONEPAGE,  } from '../../../common/Variables';

const HEADER_HEIGHT = 200

const buildTitle = (report, printClass) => {
  const { lastName, firstName } = report

  var printType = ''
  switch (printClass) {
    case FULL.classValue:
      printType = FULL.text
      break;
    case SUMMARY.classValue:
      printType = SUMMARY.text
      break;
    case HANDOUT.classValue:
      printType = HANDOUT.text
      break;
    case ONEPAGE.classValue:
      printType = ONEPAGE.text
      break;
    default:
      printType = 'Report'
  }
  
  return `Screening ${printType} - ${lastName}, ${firstName}`
}
class AdvancedReport extends Component {
  constructor(props) {
    super(props);
    const { historyList } = this.props;
    if (this.props.currentScreen === historyList[historyList.length - 2]) {
      this.props.removeLastHistoryItem(historyList);
    } else {
      this.props.addToHistory('/report', historyList);
    }

    this.mainHeaderRef = createRef();
    this.preferencesHeaderRef = createRef();
    this.basicItemHeaderRef = createRef();
    this.clinicalCareHeaderRef = createRef();
    this.patientHandoutHeaderRef = createRef();

    this.state = {
      selectedNav: 0,
      autoscroll: false,
      printClass: ''
    }
  }

  componentWillMount() {
    this.props.resetReport();
    const token = getId();
    this.props.report(token);
    this.props.advancedReport(token);
  }

  componentWillUnmount() {
    this.props.resetReport();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedNav, autoscroll, printClass } = this.state
    if (prevState.selectedNav !== selectedNav && autoscroll) {
      this.autoScrollToSection(selectedNav);
    }

    if (prevState.printClass !== printClass && printClass !== '') {
      document.title = buildTitle(this.props.advanceReport, printClass)
      window.print();
      document.title = "Screening"
      this.setState({ printClass: '' })
    }
  }

  scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();

    // While we scroll, we need to continually check for when the scrolling has hit our
    // requested offset value
    // Once we hit the value, we know the scrollTo() function has finished and we can remove
    // the listener and call the callback function
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll)
        callback()
      }
    }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    })
  }
  

  autoScrollToSection = (idx) => {
    let refs = [
      this.mainHeaderRef,
      this.preferencesHeaderRef,
      this.basicItemHeaderRef,
      this.clinicalCareHeaderRef,
      this.patientHandoutHeaderRef
    ];

    if (refs[idx].current) {
      const offset = refs[idx].current.getBoundingClientRect().top + window.scrollY - HEADER_HEIGHT
      this.scrollTo(offset, () => this.setState({ autoscroll: false, selectedNav: idx }))
    } else {
      // For whatever reason, the ref isn't available?
      // I'm not sure how this else block could happen, but it's possible
      
      // We should at least turn autoscroll to false
      // We won't change the selected nav since we didn't actually autoscroll to the nav section
      this.setState({ autoscroll: false })
    }
  };

  changeNavSelected(num) {
    const { selectedNav, autoscroll } = this.state;
    if (autoscroll) return

    var newSelected = selectedNav < num ? num : num - 1
    if (newSelected < 0) newSelected = 0
    this.setState({ selectedNav: newSelected });
  }

  render() {
    const {
      reportObject,
      spinner,
      advanceReport
    } = this.props;
    const { printClass, selectedNav } = this.state;

    if (spinner) return <Loading />
    
    if (Object.keys(reportObject).length === 0 || Object.keys(advanceReport).length === 0) {
      return null;
    }

    const reportPadding = printClass !== '' ? {} : { paddingTop: `${HEADER_HEIGHT}px` };

    return (
      <div className={`${printClass}`}>
        <StickyHeader
          firstName={advanceReport.firstName}
          lastName={advanceReport.lastName}
          birthday={advanceReport.birthday}
          lastAnswerDate={advanceReport.lastAnswerDate}
          selectedIdx={selectedNav}
          onSectionClick={(idx) => this.setState({ selectedNav: idx, autoscroll: true })}
          onItemClick={(opt) => this.setState({ printClass: opt })}
          printClass={printClass}
        />
        <main className="report-main-container" style={reportPadding}>
          <section className="report-view">
            <div className="report-detail-container">
              <div className="width">
                <div>
                  <MainHeader
                    thresholdcrossed={() => this.changeNavSelected(0)}
                    ref={this.mainHeaderRef}
                  />
                  <AlertBlock advanceReport={advanceReport} />
                  <SummaryTable advanceReport={advanceReport} />
                </div>
              </div>
              <div className="page-break" />
            </div>
            <div>
              <PreferencesHeader
                thresholdcrossed={() => this.changeNavSelected(1)}
                ref={this.preferencesHeaderRef}
              />
              <TreatmentCardsRow advanceReport={advanceReport} />
              <Preferences advanceReport={advanceReport} />
              <Barriers advanceReport={advanceReport} />
              <FooterInformation />
            </div>
          </section>
          <section>
            <BasicItemHeader
              thresholdcrossed={() => this.changeNavSelected(2)}
              ref={this.basicItemHeaderRef}
            />
            <ItemLevelReport
              reportDetails={reportObject}
            />
          </section>
          <article>
            <ClinicalCareHeader
              thresholdcrossed={() => this.changeNavSelected(3)}
              ref={this.clinicalCareHeaderRef}
            />
          <ClinicalCareResources />
          </article>
          <article>
            <PatientHandoutHeader
              thresholdcrossed={() => this.changeNavSelected(4)}
              ref={this.patientHandoutHeaderRef}
            />
            <Counseling />
            <PatientHandout />
          </article>
        </main>
      </div>
    )
  }
}
const mapStateToProps = ({ question, login, history }) => {
  const {
    currentScreen,
    historyList
  } = history;
  const {
    token
  } = login;
  const {
    advanceReport,
    reportObject,
    spinner
  } = question;
  return {
    advanceReport,
    historyList,
    spinner,
    currentScreen,
    reportObject,
    token
  };
};

export default connect(mapStateToProps, {
  advancedReport,
  addToHistory,
  removeLastHistoryItem,
  report,
  resetReport
})(AdvancedReport);
