import { signIn, postDOB, getQuestion } from '../util/api';

export const logOut = () => ({ type: 'log_out' });
export const clearError = () => ({ type: 'clear_error' });

export const signInToAccount = (email, password) => (dispatch) => {
  signIn(email, password)
    .then((success) => {
      console.log('SIGN IN SUCCESS', success);
      const { client } = success.headers;
      const token = success.headers['access-token'];
      dispatch({ type: 'log_in', uid: email, client, token, password });
    })
    .catch((error) => {
      console.log('SIGN IN FAILURE', error.response, error.message);
      dispatch({ type: 'show_login_error', message: error.response.data.errors[0] });
    });
};

export const sendDOBRequest = (dob) => (dispatch, getState) => {
  dispatch({ type: 'dob_spinner' });
  const {
    login: { uid, client, token }
  } = getState();
  postDOB(token, uid, client, dob)
    .then((success) => {
      console.log('PostDOB', success);
      console.log(success)
      dispatch({ type: 'confirm_dob' });
    })
    .catch((error) => {
      console.log('PostDOB FAILURE', error.response, error.message);
      dispatch({ type: 'show_dob_error', message: "The date of birth is incorrect" });
    });
}

export const getSubjectType = (id, cb = () => { }) => (dispatch, getState) => {
  const {
    login: { uid, client, token }
  } = getState();
  getQuestion(token, uid, client, id)
    .then((success) => {
      console.log('getSubjectType SUCCESS', success, uid, client, token, id);
      const { data } = success;
      if (data.subject_type === 'child') dispatch({ type: 'set_child' });
      cb();
    }).catch((error) => {
      console.log('getSubjectType ERROR', error.message);
      cb();
    });
}

export const resetDOBError = () => ({ type: 'show_dob_error', message: '' });

export const saveToken = (token) => (dispatch) => {
  dispatch({ type: 'save_token', surveyToken: token });
}

export const resetLoggedIn = () => ({ type: 'reset_log_in' });
export const resetLoginError = () => ({ type: 'reset_login_error' });
