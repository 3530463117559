import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export const buildTreatmentText = (child, childSat, guardian, guardianSat) => {
  if (child === 'Yes' && guardian === 'Yes') {
    if (childSat === 'Yes' && guardianSat === 'Yes') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatement and the PARENT is satisfied with this treatment.'
    }
    if (childSat === 'No' && guardianSat === 'Yes') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatement and the PARENT is satisfied with this treatment.'
    }
    if (childSat === 'No' && guardianSat === 'No') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatement and the PARENT is not satisfied with this treatment.'
    }
    if (childSat === 'Yes' && guardianSat === 'No') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatement and the PARENT is not satisfied with this treatment.'
    }
  }
  if (child === 'No' && guardian === 'Yes') {
    if (guardianSat === 'Yes') {
      return 'The ADOLESCENT reports they are not receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is satisfied with this treatment.'
    }
    if (guardianSat === 'No') {
      return 'The ADOLESCENT reports they are not receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is not satisfied with this treatment.'
    }
  }
  if (child === '' && guardian === 'Yes') {
    if (guardianSat === 'Yes') {
      return 'The PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is satisfied with this treatment.'
    }
    if (guardianSat === 'No') {
      return 'The PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is not satisfied with this treatment.'
    }
  }
  if (child === 'Yes' && guardian === '') {
    if (childSat === 'Yes') {
      return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatment.'
    }
    if (childSat === 'No') {
      return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    }
  }
  if (child === 'No' && guardian === '') {
    // if (childSat === 'Yes') {
    return 'The ADOLESCENT reports that the adolescent is not receiving treatment for a mental health problem.'
    // } if (childSat === 'No') {
    //   return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    // }
  }
  if (child === '' && guardian === 'No') {
    // if (childSat === 'Yes') {
    return 'The PARENT reports that the adolescent is not receiving treatment for a mental health problem.'
    // } if (childSat === 'No') {
    //   return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    // }
  }
  if (child === 'Yes' && guardian === 'No') {
    if (childSat === 'Yes') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is not receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatment.'
    }
    if (childSat === 'No') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is not receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    }
  }
  if (child === 'No' && guardian === 'No') {
    return 'The ADOLESCENT reports they are not receiving treatment for a mental health problem and the PARENT reports that the adolescent is not receiving treatment for a mental health problem.'
  }
}

export const treatment = (advanceReport) => {
  const { questions } = advanceReport;
  var childTx = 'N/A';
  var guardianTx = 'N/A';
  var childSat = 'N/A';
  var guardianSat = 'N/A';

  if (questions.child.length > 0) {
    const history = questions.child.filter((q) => q.question_name === 'treatment_history')[0];
    const satisfaction = questions.child.filter((q) => q.question_name === 'treatment_satisfaction')[0];
    
    if (history) childTx = history.answer_text;
    if (childTx === 'Yes' && satisfaction) childSat = satisfaction.answer_text;
  }
  
  if (questions.guardian.length > 0) {
    const gHistory = questions.guardian.filter((q) => q.question_name === 'treatment_history_2')[0];
    const gSatisfaction = questions.guardian.filter((q) => q.question_name === 'treatment_satisfaction_3')[0];

    if (gHistory) guardianTx = gHistory.answer_text;
    if (guardianTx === 'Yes' && gSatisfaction) guardianSat = gSatisfaction.answer_text;
  }
  return ({ childTx, childSat, guardianTx, guardianSat })
}

export const generateTreatmentOnePage = (txData) => {
  // Options for these are "No", "Yes", or "N/A"
  const { childTx, childSat, guardianTx, guardianSat } = txData;

  if (childTx === 'Yes' && childSat === 'Yes' && guardianSat === 'Yes') {
    return 'Adolescent is currently in treatment and parent and adolescent are both satisfied with that treatment.';
  }

  if (childTx === 'Yes' && (childSat === 'No' || guardianSat === 'No')) {
    return `Adolescent is currently in treatment but parent / adolescent reported being
    unsatisfied with treatment. It might be helpful to discuss this further in your next appt.`;
  }

  if (childTx === 'No') {
    return 'Adolescent is not currently in treatment.The following may help you discuss treatment options at your next appt.';
  }

  return ''
}

export const isNormalFontWeight = (val) => {
  if (val) return { fontWeight: 300 }
  return { fontWeight: 800 }
}


export const generateTxContent = (decidingValue) => {
  switch (decidingValue) {
    case 'Yes':
      return ({
        colorClasses: 'bg-sw-light-green',
        textcolorClasses: 'text-sw-primary-green',
        text: 'CURRENTLY in treatment',
        iconName: faCheckCircle
      })
    case 'No':
      return ({
        colorClasses: 'bg-sw-light-blue',
        textcolorClasses: 'text-black',
        text: 'NOT CURRENTLY in treatment',
        iconName: null
      })

    default: // N/A
      return ({
        colorClasses: 'bg-sw-light-blue',
        textcolorClasses: 'text-sw-light-gray',
        text: 'Treatment question not answered',
        iconName: null
      })
  }
}
export const generateSatContent = (decidingValue) => {
  switch (decidingValue) {
    case 'Yes':
      return ({
        colorClasses: 'bg-sw-light-green',
        textcolorClasses: 'text-sw-primary-green',
        text: 'SATISFIED with treatment',
        iconName: faCheckCircle
      })
    case 'No':
      return ({
        colorClasses: 'bg-sw-light-red',
        textcolorClasses: 'text-sw-primary-red',
        text: 'NOT SATISFIED with treatment',
        iconName: faExclamationTriangle
      })

    default: // N/A
      return ({
        colorClasses: 'bg-sw-light-blue',
        textcolorClasses: 'text-sw-light-gray',
        text: 'Satisfaction question not indicated or not answered',
        iconName: null
      })
  }
}