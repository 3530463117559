import React, { Component } from 'react';
import Check from './check';
import Button from '../../common/Button';
import '../../assets/scss/_survey.scss';
import ButtonContainer from '../SurveyPage/buttonContainer';

// This is kind of a crazy hotfix for the multiple choice option for this component
// The general idea is that we receive an array of options we need to show the user
// They need to be able to:
//  - select multiple options
//  - use the text field to write in an "Other" option
//  - The option "No one that I know of" is disabled if any other check boxes are selected
//  - All other checkboxes are disabled if the checkbox for the option "No one that I know of" is checked

// So we're dynamically setting the state for this since we don't know precisely how many
// options we'll get from the api. We find the "No one that I know of" option and stick it
// at the end of the array. Then we loop over the array and set our state like: `checked${idx}` = false

// Then we do a similar thing for our Checkboxes where we dynamically set the value and update state
// while mapping over our options. It's not pretty and I hate it but it should work


const isLastItem = (idx, arr) => idx + 1 === arr.length;
class Answer extends Component {
  constructor(props) {
    super(props);
    const newState = {};

    if (props.answers) {
      // Find the 'No one that I know of' or 'Not that I know of' option and
      // make sure it's the last item in our array of options
      const noOneIdx = props.answers.findIndex((answer) => {
        return answer.label === 'No one that I know of' || answer.label === 'Not that I know of';
      });
      const answers = [
        ...props.answers.slice(0, noOneIdx),
        ...props.answers.slice(noOneIdx + 1),
        { ...props.answers[noOneIdx] }
      ];
      // Dynamically setup an object and then populate state with it
      answers.forEach((_, idx) => newState[`checked${idx}`] = false);
      this.answers = answers;
    }
    this.state = { ...newState };
  }

  findValueFromLabel(label) {
    return this.props.answers.find((a) => a.label === label).value;
  }

  oneTouchAnswer() {
    if (this.props.answers === undefined) return null;

    const btns = this.props.answers.map((a) => {
      const extraPrimaryTextStyle = `btn-contain-txt ${a.label.length < 70 ? 'small-text' : 'smaller-text'}`;
      return (
        <div key={a.value} className='py-2'>
          <Button
            buttonText={a.label}
            disabled={false}
            extraPrimaryStyle="btn-contain-button"
            extraPrimaryTextStyle={extraPrimaryTextStyle}
            extraBtnStyle="purple-bg btn"
            onClick={() => this.props.onQuestionAnswered(a.value)}
          />
        </div>
      )
  })

    return (
      <div className="answer-view pb-10">
        <form className="flex flex-col">
          {btns}
        </form>
      </div>
    )
  }

  lastIsDisabled(index) {
    const allButLast = []
    this.answers.forEach((_, idx) => {
      if (idx !== index) allButLast.push(this.state[`checked${idx}`])
    });
    return !allButLast.every((item) => item === false);
  }

  render() {
    const { type } = this.props;
    if (type !== 'multiple_choice') return this.oneTouchAnswer();

    let isDisabled = true;
    for (let i = 0; i < this.answers.length; i++) {
      if (this.state[`checked${i}`]) isDisabled = false;
    }

    const options = this.answers.map((ans, idx) => {
      if (isLastItem(idx, this.answers)) {
        return (
          <Check
            key={ans.value}
            checked={this.state[`checked${idx}`]}
            disabled={this.lastIsDisabled(idx)}
            id={`answer${this.answers[idx].sequence}`}
            label={this.answers[idx].label}
            setAnswer={() => {
              this.props.setAnswer(this.answers[idx].value, this.state[`checked${idx}`]);
              this.setState({ [`checked${idx}`]: !this.state[`checked${idx}`] });
            }}
          />
        )
      }

      if (this.answers[idx].label === 'Other') {
        return (
          <div key={ans.value}>
            <Check
              checked={this.state[`checked${idx}`]}
              disabled={this.state[`checked${this.answers.length - 1}`]}
              id={`answer${this.answers[idx].sequence}`}
              label={this.answers[idx].label}
              setAnswer={() => {
                this.props.setAnswer(this.answers[idx].value, this.state[`checked${idx}`]);
                this.setState({ [`checked${idx}`]: !this.state[`checked${idx}`] });
              }}
            />
            <input
              type="text"
              className="answer-input"
              onChange={(text) => this.props.onOpenTextChange({ text })}
            />
          </div>
        )
      }

      return (
        <Check
          key={ans.value}
          checked={this.state[`checked${idx}`]}
          disabled={this.state[`checked${this.answers.length - 1}`]}
          id={`answer${this.answers[idx].sequence}`}
          label={this.answers[idx].label}
          setAnswer={() => {
            this.props.setAnswer(this.answers[idx].value, this.state[`checked${idx}`]);
            this.setState({ [`checked${idx}`]: !this.state[`checked${idx}`] });
          }}
        />
      )
    })

    return (
      <div style={{ flex: 3 }}>
        {options}
        <ButtonContainer disabled={isDisabled} nextFunction={() => this.props.onMultipleChoiceFinished()} />
      </div>
    );
  }
}

export default Answer;