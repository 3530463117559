const INITIAL_STATE = {
  uid: '',
  client: '',
  token: '',
  password: '',
  loggedIn: false,
  message: '',
  surveyToken: '',
  isDOBConfirmed: false,
  DOBSpinner: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'log_in':
    return {
      ...state,
      uid: action.uid,
      client: action.client,
      token: action.token,
      password: action.password,
      loggedIn: true,
      DOBSpinner: false
    };
  case 'show_login_error':
    return {
      ...state,
      message: action.message
    };
  case 'reset_login_error':
    return {
      ...state,
      message: ''
    };
  case 'reset_log_in':
    return {
      ...state,
      loggedIn: false,
      isDOBConfirmed: false
    };
  case 'save_token':
    return {
      ...state,
      surveyToken: action.surveyToken
    };
  case 'confirm_dob':
    return {
      ...state,
      isDOBConfirmed: true,
      DOBSpinner: false
    };
  case 'dob_spinner':
    return {
      ...state,
      DOBSpinner: true
    }
  case 'show_dob_error':
    return {
      ...state,
      dob_error: action.message,
      DOBSpinner: false
    }
  default:
    return state;
  }
};
