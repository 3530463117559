
import React from 'react';
import '../../assets/scss/_login.scss';

const SurveyDone = () => (
  <div className="background-image">
    <div className="main-view">
      <div className="login-title">
        Survey Complete
      </div>
      <div className="login-title">
        Thank you!
      </div>
    </div>
  </div>
);

export default SurveyDone;