import React from 'react';

const guardianAnswer = (advanceReport) => {
  const { guardian } = advanceReport.questions
  if (guardian.length === 0) return null

  return guardian.find((q) => q.question_name === 'general_1')
}

const answerData = (item) => {
  if (!item) return null

  if (item.answer.includes('No')) return <p>No Concern</p>

  return (
    <div>
      {item.question_text}&nbsp;{item.answer_text}
    </div>
  )
}

const notesData = (item) => {
  if (!item) return null
  if (item.answer.includes('Yes')) {
    return <p className="text-xs py-3 leading-loose">Inquire more on clinical interview</p>
  }
  return
}

const ConcernsRow = ({ advanceReport }) => {
  // TODO: ichart doesn't use this, but leaving until confirmed
  return null;










  
  // We only care about 1 specific question, so we grab the correct question
  // before rendering and then use it as needed
  const answer = guardianAnswer(advanceReport)

  

  return (
    <div className="table-row border-t-2 border-sw-lightest-gray align-top" role="row">
      <div className="table-cell font-bold text-lg text-sw-dark-blue py-3" role="rowheader">
        Parent
        <br />
        Emotional
        <br />
        Concerns
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3  align-top leading-loose" role="gridcell">
        Not Asked
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {answerData(answer) || "N/A"}
      </div>
      <div className="table-cell align-top leading-loose" role="gridcell">
        {notesData(answer)}
      </div>
    </div>
  )
}

export default ConcernsRow