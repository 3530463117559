import React from 'react';
import BarrierCell from './BarrierCell';
import {
  CHILD_MAP,
  PARENT_MAP,
  ANSWER_MAP
} from './barriersMappings'

const discrepancyData = (child, parent) => {
  const childArr = [];
  const parentArr = [];
  if (child.length > 0) {
    child.forEach((q) => {
      if (q.question.startsWith('bash')) {
        childArr.push({ text: CHILD_MAP[q.question_text], isBarrier: q.answer_text.trim() == 'Agree' });
      }
      if (q.question.startsWith('ams')) {
        childArr.push({ text: 'medication', isBarrier: ANSWER_MAP[q.answer_text.trim().toLowerCase()] == 'Agree' });
      }
    })
  }
  if (parent.length > 0) {
    parent.forEach((q) => {
      if (q.question_name.startsWith('bash')) {
        parentArr.push({ text: PARENT_MAP[q.question_text], isBarrier: q.answer_text.trim() == 'Agree' })
      }
      if (q.question.startsWith('ams')) {
        parentArr.push({ text: 'medication', isBarrier: ANSWER_MAP[q.answer_text.trim().toLowerCase()] == 'Agree' })
      }
    })
  }

  // childArr.sort((a, b) => {
  //   let aIsInArr = parentArr.find((item) => item === b)
  //   let bIsInArr = parentArr.find((item) => item === a)
  //   if ((aIsInArr && bIsInArr) || (!aIsInArr && !bIsInArr)) return 0;
  //   if (aIsInArr) return 1;
  //   if (bIsInArr) return -1;
  // })
  // parentArr.sort((a, b) => {
  //   let aIsInArr = childArr.find((item) => item === b)
  //   let bIsInArr = childArr.find((item) => item === a)
  //   if ((aIsInArr && bIsInArr) || (!aIsInArr && !bIsInArr)) return 0;
  //   if (aIsInArr) return 1;
  //   if (bIsInArr) return -1;
  // })
  return ({
    child: childArr,
    parent: parentArr
  })
}

const Barriers = ({ advanceReport }) => {
  const discrepancyObj = discrepancyData(advanceReport.questions.child, advanceReport.questions.guardian);
  return (
    <div className="barriers-to-treatment container print-space overflow-hidden pb-6">
      <div className="overflow-x-scroll flex flex-col w-full">
        <h3 className="text-xl font-bold pb-4 pt-8 text-sw-dark-blue">Barriers to Treatment</h3>
        <div className="flex w-full flex-col">
          <div className="flex flex-row items-center">
            <div className="w-28">
              <h4 className="p-2 text-xs text-sw-dark-blue font-bold items-center">ADOLESCENT</h4>
            </div>
            <ul className="flex flex-row">
              {discrepancyObj['child'].map((item) => <BarrierCell key={item.text} item={item} />)}
            </ul>
          </div>
          <div className="flex flex-row">
            <div className="w-28">
              <h4 className="p-2 text-xs text-sw-dark-blue font-bold items-center">PARENT</h4>
            </div>
            <ul className="flex flex-row">
              {discrepancyObj['parent'].map((item) => <BarrierCell key={item.text} item={item} />)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Barriers