import React from 'react';
import { connect } from 'react-redux';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import '../../assets/scss/_survey.scss';

const DISCLAIMER_TEXT = 'This is a screening and not meant to diagnose. Please talk to your treatment provider to help you interpret your results.';
const CHILD_TEXT = 'This is a mental health screening questionnaire. It will ask you questions about topics like mood, anxiety, substance use, and concerns that you have about mental health. Please answer these questions as honestly and accurately as possible. Your provider will be able to talk more with you about your responses and any questions that you may have.';
const GUARDIAN_TEXT = "This is a mental health screening questionnaire for your child. It will ask you questions about topics like your child’s mood, your child's behavior, and concerns that you have about your child’s mental health.Please answer these questions as honestly and accurately as possible.Your child’s provider will be able to talk more you with you and your child about your responses and any questions that you may have.";

const WelcomeScreen = ({ onBtnClick, child, subject_type_set }) => {
  if (!subject_type_set) return <Spinner />;

  const text = child ? CHILD_TEXT : GUARDIAN_TEXT;
  return (
    <div className="background-image">
      <div className="main-view">
        <p className="text-lg leading-7 text-white w-5/6 text-center md:text-2xl md:leading-10 ">
          {DISCLAIMER_TEXT}
          <br />
          <br />
          {text}
        </p>
        <div className="btn-contain">
          <Button
            buttonText="Continue"
            disabled={false}
            onClick={onBtnClick}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ question }) => {
  const { child, subject_type_set } = question;
  return {
    child,
    subject_type_set
  };
};

export default connect(mapStateToProps, {})(WelcomeScreen);
