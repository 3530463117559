import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from '../../common/Header';
import ProgressBar from './ProgressBar';
import Help from '../Help';
import Disclaimer from '../Disclaimer';
import {
  setInstructions,
  setQuestionNumber,
  acceptDisclaimer,
  acceptMeme2,
  acceptMeme3
} from '../../actions/questionActions';
import {
  addToHistory,
  removeLastHistoryItem,
  setCurrentScreen
} from '../../actions/historyActions';
import { resetLoggedIn } from '../../actions/loginActions';
import Survey from './Survey';
import '../../assets/scss/_survey.scss';

function headline(percentage, subject) {
  if (percentage === '100%') return '';
  if (subject === 'child') return 'Youth Survey';
  if (subject === 'guardian') return 'Parent Survey';
}

class SurveyPage extends Component {
  constructor(props) {
    super(props);
    const { historyList } = this.props;
    if (this.props.currentScreen === historyList[historyList.length - 2]) {
      this.props.removeLastHistoryItem(historyList);
    } else {
      this.props.addToHistory('/survey', historyList);
    }
    this.state = {
      overlay: false,
      redirect: false,
      instructionsRedirect: false
    };
  }

  maybeSetInstructions() {
    const { questions, instructions, researchInstructions } = this.props;
    const { instructions: qIns, researchInstructions: qResIns } = questions;

    if (qIns === undefined && qResIns === undefined) return null;

    const checkA = qIns !== instructions || qResIns !== researchInstructions
    const checkB = qIns !== null && qIns !== ''
    const checkC = qResIns !== null && qResIns !== ''

    if (checkA && (checkB || checkC)) {
      this.props.setInstructions(qIns, qResIns);
      this.setState({ instructionsRedirect: true });
    }
  }

  memes() {
    const {
      questions,
      disclaimer,
      meme2,
      meme3,
      child
    } = this.props;
    const { subject, question } = questions;
    const showMeme3 = question &&
                      question.includes("Are you currently receiving help for an emotional problem") &&
                      child &&
                      meme3;

    if (!child || disclaimer) {
      return (
        <Disclaimer
          visible={disclaimer}
          disclaimer
          bullets
          parentGuardian={subject}
          visibleFunction={() => this.props.acceptDisclaimer()}
        />
      )
    } else if (meme2) {
      return (
        <Disclaimer
          visible={!disclaimer && meme2}
          memeInfo="meme2"
          memes
          parentGuardian={subject}
          visibleFunction={() => this.props.acceptMeme2()}
        />
      )
    } else if (showMeme3) {
      return (
        <Disclaimer
          visible={!disclaimer && meme3}
          memeInfo="meme3"
          memes
          parentGuardian={subject}
          visibleFunction={() => this.props.acceptMeme3()}
        />
      )
    }
  }

  render() {
    const {
      overlay,
      redirect,
      instructionsRedirect
    } = this.state;
    const {
      questions,
      complete,
      disclaimer,
      meme2,
      questionNumber,
      surveyToken
    } = this.props;
    const { progress, subject, instructions } = questions;

    const percentage = complete ? '100%' : progress;

    if (redirect) {
      this.props.setQuestionNumber(questionNumber);
      return <Redirect push to={{ pathname: "/", search: `?survey=${surveyToken}` }} />;
    }

    const redirectToInstructions = (instructionsRedirect && !meme2) ||
                                  (instructionsRedirect && subject === 'guardian' && !disclaimer)
    if (redirectToInstructions) {
      this.props.setQuestionNumber(questionNumber);
      return <Redirect push to="/instructions" />;
    }

    return (
      <div className="main-container">
        <Header
          headline={headline(percentage, subject)}
          rightText="EXIT"
          visible={overlay}
          closeModal={() => this.setState({ overlay: false })}
          exit={() => {
            this.props.resetLoggedIn();
            this.setState({ overlay: false, redirect: true });
          }}
          rightFunction={() => this.setState({ overlay: true })}
        />
        {this.memes()}
        <Survey maybeSetInstructions={() => this.maybeSetInstructions()} />
        <Help instructions={instructions === null ? this.props.instructions : instructions} />
        <ProgressBar percentage={percentage} />
      </div>
    );
  }
}
const mapStateToProps = ({ question, history, login }) => {
  const {
    instructions,
    questions,
    complete,
    questionNumber,
    researchInstructions,
    disclaimer,
    meme2,
    meme3,
    child
  } = question;
  const {
    currentScreen,
    historyList
  } = history;
  const { surveyToken } = login;
  return {
    instructions,
    currentScreen,
    historyList,
    questions,
    complete,
    questionNumber,
    researchInstructions,
    disclaimer,
    meme2,
    meme3,
    child,
    surveyToken
  };
};

export default connect(mapStateToProps, {
  setInstructions,
  addToHistory,
  removeLastHistoryItem,
  setCurrentScreen,
  setQuestionNumber,
  resetLoggedIn,
  acceptDisclaimer,
  acceptMeme2,
  acceptMeme3
})(SurveyPage);
