import React from 'react';
import Overlay from '../../common/Overlay';

const Disclaimer = (props) => {
  if (props.parentGuardian === 'child' || props.parentGuardian === 'complete') {
    const questions = [
      {
        para: 'We will be asking you some sensitive questions about',
        bold: ' your emotional health.'
      },
      {
        para: 'These are questions',
        bold: ' we ask everyone ',
        secondPara: 'your age.'
      },
      {
        para: '',
        bold: '1 in 5 people',
        secondPara: ' have mental health symptoms and suicide is the second cause of death in young people.'
      },
      {
        para: 'If you are feeling down, it’s',
        bold: ' important ',
        secondPara: 'to get help early.'
      },
      {
        para: '',
        bold: 'We take your confidentiality seriously. ',
        secondPara: 'Your healthcare provider will see the answers to your questions (and the research team if you are in the study).'
      },
      {
        para: 'If we become concerned about your safety, your caregiver, primary care provider, and mental health providers will be contacted.'
      }
    ];
    return (
      <div>
        <Overlay
          modalVisible={props.visible}
          returnToQuestion={() => props.visibleFunction()}
          question={questions}
          patient
          bullets
          memes={props.memes}
          disclaimer={props.disclaimer}
          memeInfo={props.memeInfo}
        />
      </div>
    );
  }
  const questions = [
    {
      para: 'We will be asking you and your child some sensitive questions about',
      bold: ' their emotional health.'
    },
    {
      para: 'These are questions',
      bold: ' we ask everyone ',
      secondPara: 'their age.'
    },
    {
      para: '',
      bold: '1 in 5 people',
      secondPara: ' have mental health symptoms and suicide is the second cause of death in young people.'
    },
    {
      para: 'If your child is feeling down, it’s',
      bold: ' important ',
      secondPara: 'to get help early.'
    },
    {
      para: '',
      bold: 'We take your confidentiality seriously. ',
      secondPara: 'Only your healthcare provider will see the answers to your questions (and the research team if you are in the study). The exception is if the way you or your child answers suggests your child or someone else may be in danger.'
    },
    {
      para: 'The results of these questions will be included in your child’s electronic health record.'
    }
  ];
  return (
    <div>
      <Overlay
        modalVisible={props.visible}
        returnToQuestion={() => props.visibleFunction()}
        question={questions}
        bullets
      />
    </div>
  );
};

export default Disclaimer;
