import React from 'react';
import DetailsQuestionTable from './DetailsQuestionTable'
import ParentGad from './ParentItemLevelSections/ParentGad'
import ParentMania from './ParentItemLevelSections/ParentMania'
import { get } from '../../../util/helperFunctions'
import ParentEmotional from './ParentItemLevelSections/ParentEmotional';

const ParentItemLevelReport = ({
  reportDetails,
  cmrsDetails,
  gadDetails
}) => {
  if (reportDetails.questions.guardian.length === 0) {
    return (
      <div className="parent-item-level-report container">
        <div className="basic-item-header text-3xl font-black text-sw-dark-blue leading-10 py-8 border-t-8 border-sw-dark-blue">
          <h2 className="pr-2">Screening Item Level Report: <span className="underline">Parent</span></h2>
        </div>
      </div>
    )
  }

  // The get() function allows us to dig through the object for an answer and default to an empty string.
  // Per the above TODO, we might want to default to something else like null and allow smaller components
  // to run a check and render some default information if necessary
  const gad = get(['results', 'guardian', 'gad'], reportDetails, '')
  const cmrs = get(['results', 'guardian', 'cmrs'], reportDetails, '')
  const mania = get(['results', 'guardian', 'mania'], reportDetails, '')
  const general = get(['results', 'guardian', 'general'], reportDetails, '')

  return (
    <div className="parent-item-level-report container">
      <div className="basic-item-header text-3xl font-black text-sw-dark-blue leading-10 py-8 border-t-8 border-sw-dark-blue">
        <h2 className="pr-2">Screening Item Level Report: <span className="underline">Parent</span></h2>
      </div>

      {/* GAD */}
      <ParentGad gad={gad} gadDetails={gadDetails} />

      <div className="mx-auto mb-8 border-t-2 border-sw-lightest-gray">
        {/* MANIA */}
        <ParentMania reportDetails={reportDetails} mania={mania} cmrs={cmrs} />
        <DetailsQuestionTable questionType={cmrsDetails} />

        {/* EMOTIONAL CONCERN */}
        <ParentEmotional general={general} />
      </div>
    </div>
  )
}

export default ParentItemLevelReport
