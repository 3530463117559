import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../../assets/scss/_common.scss';

const MemeComponent = (props) => {
  let altText = 'The information you share is just between YOU and your doctor... Unless you are thinking of suicide. Please let us know so we can help.';
  if (props.image === 3) {
    altText = "1 in 5 teens has a mental health or substance abuse problem. If that's you, you are not alone";
  }
  if (props.image === 4) {
    altText = 'Even if today is not the day you want to talk to your doctor about how you are feeling, you should know this is a safe place to come back to talk at any time!';
  }
  return (
    <div>
      <div className="meme-overlay-container" style={{ backgroundColor: 'transparent' }}>
        <div className="blurred-background" />
        <div className='meme'>
          <div className="meme-header">
            <button
              onClick={() => props.returnToQuestion()}
              className="hover:text-sw-dark-blue meme-btn"
            >
              <div style={{ paddingRight: 10 }}>Continue</div>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
          <img
            src={`/SWMessage${props.image}.png`}
            alt={altText}
            style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default MemeComponent;
