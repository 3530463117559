import {
  faDollarSign,
  faCar,
  faFrown,
  faClock,
  faEyeSlash,
  faBriefcaseMedical
} from '@fortawesome/free-solid-svg-icons'

export const CHILD_MAP = {
  "I could not afford to see a therapist even if I wanted to.": 'cost',
  "I would have trouble finding transportation to get to therapy appointments.": 'transportation',
  "Even if I wanted to, I wouldn't have time to see a therapist.": 'time',
  "If I had a problem and told a therapist, the therapist would not keep it a secret.": 'secret',
  "Even if I had a problem, I'd be too embarrassed to talk to a therapist about it.": 'embarrassed'
}

export const PARENT_MAP = {
  "I could not afford to get professional help for my child's problem(s) even if I wanted to.": 'cost',
  "I would have trouble finding transportation for my child to get to therapy appointments.": 'transportation',
  "Even if I wanted to, I wouldn't have time to get professional help for my child's problem(s).": 'time',
  "If I got professional help for my child's problem, the problem would not be kept secret.": 'secret',
  "Even if I feel my child had a problem, I'd be too embarrassed to get professional help for it.": 'embarrassed'
}

export const DISC_MAP = {
  'cost': {
    title: "Cost",
    icon: faDollarSign
  },
  'transportation': {
    title: "Transportation",
    icon: faCar
  },
  'embarrassed': {
    title: "Stigma",
    icon: faFrown
  },
  'time': {
    title: "Time",
    icon: faClock
  },
  'secret': {
    title: "Confidentiality",
    icon: faEyeSlash
  },
  'medication': {
    title: 'Medication',
    icon: faBriefcaseMedical
  }
}

export const ANSWER_MAP = {
  "not worried at all": "Disagree",
  "somewhat worried": "Agree",
  "very worried": "Agree"
}