import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from '../../common/Button';
import TextField from '../../common/TextField';
import { toggleEye } from '../../actions/uiActions';
import {
  signInToAccount,
  resetLoginError,
  saveToken
} from '../../actions/loginActions';
import {
  gettingQuestion,
  resetOnLogin
} from '../../actions/questionActions';
import { setCurrentScreen } from '../../actions/historyActions';
import '../../assets/scss/_login.scss';
import { getId } from '../../util/helperFunctions';
import MissingTokenView from '../MissingTokenView';
import WelcomeScreen from './WelcomeScreen';
import DOBConfirmation from './DOBConfirmation';

class Login extends Component {
  constructor(props) {
    super(props);
    const { surveyToken } = this.props;
    this.props.resetOnLogin();
    this.newToken = getId();

    if (surveyToken !== this.newToken && this.newToken !== '') {
      this.props.saveToken(this.newToken)
    }
    this.state = {
      emailInvalid: true,
      passwordInvalid: true,
      email: '',
      password: '',
      showPassword: false,
      hasFinishedWelcome: false,
      isConfirmed: false
    };
  }
  
  changeEmail(e) {
    const email = e.target.value;
    this.setState({ email, emailInvalid: email.length <= 6 });
  }

  changePassword(e) {
    const password = e.target.value;
    this.setState({ password, passwordInvalid: password.length <= 5 });
  }

  handleSignInClick() {
    const { email, password } = this.state;
    this.props.resetLoginError();
    this.props.signInToAccount(email, password);
  }

  render() {
    if (this.newToken === '') return <MissingTokenView />

    const { loggedIn, report, message, isDOBConfirmed, complete } = this.props;
    const {
      passwordInvalid,
      emailInvalid,
      email,
      password,
      showPassword,
      hasFinishedWelcome
    } = this.state;
  
    if (loggedIn && report) {
      this.props.setCurrentScreen('/report');
      return <Redirect push to="/report" />;
    }

    if (loggedIn && !isDOBConfirmed) {
      return <DOBConfirmation token={this.newToken} />;
    }

    if (loggedIn && complete) { return <Redirect push to="/survey-done" />; }

    if (loggedIn && !hasFinishedWelcome && !complete) {
      this.props.gettingQuestion(this.newToken);
      return <WelcomeScreen onBtnClick={() => this.setState({ hasFinishedWelcome: true })}/>;
    }

    if (loggedIn) {
      this.props.setCurrentScreen('/survey');
      return <Redirect push to="/survey" />;
    }

    return (
      <div className="background-image">
        <div className="flex flex-col">
          <div className="main-view gap-12">
            <h1 className="text-white text-center text-5xl md:text-6xl">
              SCREENING WIZARD
            </h1>
            <p className="description text-white font-bold text-3xl md:text-4xl">
              Login
            </p>
            {message !== '' && <p className="error">{message}</p>}
            <div className="input-container">
              <TextField
                type="text"
                placeholder="Enter Your User ID"
                value={email}
                correct={emailInvalid}
                name="email"
                data="true"
                className="text-box"
                onChange={(e) => this.changeEmail(e)}
              />
            </div>
            <div className="input-container">
              <TextField
                type="password"
                placeholder="Enter Your Password"
                value={password}
                name="password"
                className="text-box"
                imageName="Eye"
                data="true"
                correct={passwordInvalid}
                seePassword={showPassword}
                toggleEye={() => this.setState({ showPassword: !this.state.showPassword })}
                onChange={(e) => this.changePassword(e)}
              />
            </div>
          </div>
          <div className="btn-contain">
            <Button
              buttonText="Sign In"
              disabled={emailInvalid || passwordInvalid}
              onClick={() => this.handleSignInClick()}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ ui, login, question }) => {
  const { showPassword } = ui;
  const { report, complete } = question;
  const { message, loggedIn, surveyToken, isDOBConfirmed } = login;
  return {
    showPassword,
    message,
    loggedIn,
    surveyToken,
    isDOBConfirmed,
    report,
    complete
  };
};

export default connect(mapStateToProps, {
  toggleEye,
  signInToAccount,
  resetLoginError,
  setCurrentScreen,
  gettingQuestion,
  saveToken,
  resetOnLogin
})(Login);
