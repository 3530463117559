import moment from 'moment';

const INITIAL_STATE = {
  answerId: 0,
  instructions: '',
  questions: {},
  questionNumber: 1,
  complete: false,
  reportObject: {},
  advanceReport: {},
  researchInstructions: '',
  updated: moment().format('MMMM DD, YYYY'),
  disclaimer: true,
  meme2: true,
  meme3: true,
  child: false, // This should eventually be an enum or set with null and then turn to a bool
  subject_type_set: false, // This should be combined with `child` property
  report: false,
  spinner: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'answer_id':
    return {
      ...state,
      answerId: action.answerId
    };

  case 'question_instructions':
    return {
      ...state,
      instructions: action.instructions
    };
  case 'other_instructions':
    return {
      ...state,
      researchInstructions: action.researchInstructions
    };

  case 'set_questions':
    return {
      ...state,
      questions: action.questions
    };
  case 'complete_survey':
    return {
      ...state,
      complete: true
    };
  case 'reset_status':
    return {
      ...state,
      complete: false
    };
  case 'save_question_number':
    return {
      ...state,
      questionNumber: action.questionNumber
    };
  case 'report_object':
    return {
      ...state,
      reportObject: action.reportObject
    };
  case 'advanced_report':
    return {
      ...state,
      advanceReport: action.advanceReport
    };
  case 'accept_disclaim':
    return {
      ...state,
      disclaimer: false
    }
  case 'reset_disclaim':
    return {
      ...state,
      disclaimer: true
    }
  case 'accept_meme2':
    return {
      ...state,
      meme2: false
    };
  case 'reset_meme2':
    return {
      ...state,
      meme2: true
    };
  case 'accept_meme3':
    return {
      ...state,
      meme3: false
    };
  case 'reset_meme3':
    return {
      ...state,
      meme3: true
    };
  case 'set_child':
    return {
      ...state,
      child: true
    };
  case 'reset_child':
    return {
      ...state,
      child: false,
      subject_type_set: false
    };
  case 'subject_type_set':
    return {
      ...state,
      subject_type_set: true
    };
  case 'set_report':
    return {
      ...state,
      report: true
    };
  case 'reset_report':
    return {
      ...state,
      report: false,
      reportObject: {},
      advanceReport: {},
      spinner: false
    };
  case 'report_spinner':
    return {
      ...state,
      spinner: true
    };
  case 'reset_spinner':
    return {
      ...state,
      spinner: false
    };
  default:
    return state;
  }
};
