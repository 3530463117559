import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../common/Spinner';
import BasicItemHeader from '../AdvancedReport/NavHeaders/BasicItemHeader'
import Handouts from '../Handouts';
import ItemLevelReport from './ItemLevelReport'

import './SWHandouts.css'

const DetailedReport = ({ reportDetails, spinner }) => {
  if (spinner || Object.keys(reportDetails).length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner />
      </div>
    )
  }

  return (
    <div>
      <section>
        <BasicItemHeader />
        <ItemLevelReport reportDetails={reportDetails}/>
      </section>
      <article>
        <Handouts />
      </article>
    </div>
  )
}
const mapStateToProps = ({ question }) => {
  const { spinner } = question;
  return {
    spinner
  };
};

export default connect(mapStateToProps, {
})(DetailedReport);
