import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import { sendDOBRequest, resetDOBError, getSubjectType } from '../../actions/loginActions';
import '../../assets/scss/_survey.scss';

function now() {
  var curr = new Date();
  curr.setDate(curr.getDate());
  return curr.toISOString().substring(0, 10);
}

const DOBConfirmation = ({ dob_error, DOBSpinner, child, token, sendDOBRequest, getSubjectType, resetDOBError }) => {
  const [dobValue, setDobValue] = useState(now());
  const [hasSubjectType, setHasSubjectType] = useState(false);

  useEffect(() => {
    getSubjectType(token, () => setHasSubjectType(true));
  }, [token, getSubjectType]);

  const handleChange = (e) => {
    resetDOBError();
    setDobValue(moment(e.target.value).format('YYYY-MM-DD'));
  } 

  if (DOBSpinner || !hasSubjectType) {
    return (
      <div className="background-image">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="background-image">
      <div class="flex flex-col p-4">
        <div className="main-view gap-12">
          {dob_error !== '' && <p className="error">{dob_error}</p>}
          <p className="text-white font-bold text-3xl text-center md:text-4xl">
            {`Please enter your ${child ? '' : "child's"} date of birth:`}
          </p>
          <input
            id="dob-input"
            type="date"
            value={dobValue}
            onChange={handleChange}
            className="flex text-xl h-12 px-2 w-fit"
          />
        </div>
        <div className="btn-contain">
          <Button
            buttonText="Submit"
            disabled={!dobValue}
            onClick={() => sendDOBRequest(dobValue)}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ login, question }) => {
  const { DOBSpinner, dob_error } = login;
  const { child } = question;
  return {
    DOBSpinner,
    dob_error,
    child
  };
};

export default connect(mapStateToProps, {
  sendDOBRequest, resetDOBError, getSubjectType
})(DOBConfirmation);
