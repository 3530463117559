import React from 'react';
import {
  childReadiness,
  guardianReadiness,
  childPreference,
  guardianPreference
} from './treatmentFunctions'
import PreferenceCard from './PreferenceCard';

const Preferences = ({ advanceReport }) => {
  const childReadinessData = childReadiness(advanceReport)
  const childPreferenceData = childPreference(advanceReport)
  const guardianReadinessData = guardianReadiness(advanceReport)
  const guardianPreferenceData = guardianPreference(advanceReport)
  return (
    <div className="preferences-cards container flex flex-col justify-start">
      <div className="pref-card-holder print-flex print-no-mb flex flex-col w-full mx-auto gap-6 sm:flex-row sm:gap-8 md:gap-20">
        <div className="pref-card-row-one print-no-mb print-pt flex-col w-full mb-5 sm:flex-row sm:w-1/2">
          <div className="title-print text-sw-dark-blue text-xs font-bold mb-5">
            ADOLESCENT
          </div>
          <PreferenceCard
            readinessData={childReadinessData}
            preferenceData={childPreferenceData}
          />
        </div>
        <div className="pref-card-row-two flex-col w-full sm:flex-row sm:w-1/2">
          <div className="title-print text-sw-dark-blue text-xs font-bold mb-5">
            PARENT
          </div>
          <PreferenceCard
            readinessData={guardianReadinessData}
            preferenceData={guardianPreferenceData}
          />
        </div>
      </div>
    </div>
  );
}

export default Preferences;
