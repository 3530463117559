import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import Button from '../../common/Button';
import {
  addToHistory,
  removeLastHistoryItem,
  setCurrentScreen
} from '../../actions/historyActions';
import '../../assets/scss/_survey.scss';

class Instructions extends Component {
  constructor(props) {
    super(props);
    const { historyList } = this.props;
    if (this.props.currentScreen === historyList[historyList.length - 2]) {
      this.props.removeLastHistoryItem(historyList);
    } else {
      this.props.addToHistory('/instructions', historyList);
    }
    this.state = {
      survey: false,
      overlay: false,
      redirect: false
    };
  }
  render() {
    const { surveyToken, questions } = this.props;
    const { survey, overlay, redirect } = this.state;
    const { instructions } = questions;

    if (redirect) {
      return <Redirect push to={{ pathname: "/", search: `?survey=${surveyToken}` }} />;
    }
    const redirectToSurvey = survey || instructions === null || instructions === '';
    if (redirectToSurvey) return <Redirect push to="/survey" />;
    
    return (
      <div style={{ alignItems: 'center', flex: 1 }}>
        <Header
          headline="SCREENING WIZARD"
          rightText="EXIT"
          visible={overlay}
          closeModal={() => this.setState({ overlay: false })}
          exit={() => this.setState({ overlay: false, redirect: true })}
          rightFunction={() => this.setState({ overlay: true })}
        />
        <div className="instruction-view">
          <div className='flex flex-row w-full'>
            <div className="flex justify-center mt-6 mb-10 flex-1 text-xl">
              <div className='w-2/3 text-center'>
                {instructions}
              </div>
            </div>
          </div>
          <Button
            disabled={false}
            buttonText="Continue"
            onClick={() => this.setState({ survey: true })}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ question, history, login }) => {
  const {
    currentScreen,
    historyList
  } = history;
  const {
    questions,
    child,
    questionNumber
  } = question;
  const { surveyToken } = login;
  return {
    questions,
    currentScreen,
    historyList,
    child,
    surveyToken,
    questionNumber
  };
};

export default connect(mapStateToProps, {
  removeLastHistoryItem,
  setCurrentScreen,
  addToHistory
})(Instructions);
