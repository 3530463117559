import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DISC_MAP } from './barriersMappings'


const BarrierCell = ({ item }) => {
  const { text, isBarrier } = item;
  return (
    <li className={`barrier-item text-xs text-sw-dark-blue ${isBarrier ? '' : 'text-sw-light-gray'} py-2 flex flex-col gap-2 items-center w-24`}>
      <FontAwesomeIcon icon={DISC_MAP[text].icon} className="text-xl text-center" />
      <div className={`text-xs text-sw-dark-blue ${isBarrier ? '' : 'text-sw-light-gray'} text-center`}>
        {DISC_MAP[text].title}
      </div>
    </li>
  )
}

export default BarrierCell
