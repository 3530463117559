import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Question from '../Questions';
import Answer from '../Answers';
import ThankYou from '../ThankYouPage';
import {
  setAnswerId,
  resetQuestionNumber,
  resetOnLogin,
  setQuestionNumber,
  goBackwards
} from '../../actions/questionActions';
import '../../assets/scss/_survey.scss';

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finishSurvey: false,
      answer: 0,
      openAnswer: '',
      multipleAnswers: [],
      multiple: false,
      showMeme: true
    };
  }

  render() {
    const { questions, complete, child, questionNumber } = this.props;
    const { question, questionId, instanceId, type, answers } = questions;
    const { answer, openAnswer, multipleAnswers, multiple, showMeme, finishSurvey } = this.state;
    if (finishSurvey) return <Redirect push to="/survey-done" />;

    const sendAnswer = multiple ? multipleAnswers : answer;
    if (complete) {
      // We completed the survey - we show the thank you page always, but only show the meme if the survey taker was a child
      return (
        <ThankYou
          submitButton={() => {
            this.props.resetOnLogin();
            this.props.resetQuestionNumber();
            this.setState({ finishSurvey: true });
          }}
          // onBackClick={() => {
          //   console.log('instance ID', instanceId)
          //   const cb = () => this.props.setQuestionNumber(questionNumber - 1);
          //   this.props.goBackwards(instanceId, cb);
          // }}
          disclaimerVisible={showMeme && child}
          visibleFunction={() => this.setState({ showMeme: false })}
        />
      );
    }
    this.props.maybeSetInstructions();
    return (
      <div key={questionId} className="survey-question-container">
        <div className='flex flex-row w-full'>
          <div className='w-56 pt-2 pl-4 lg:pl-6'>
            {questionNumber > 1 && <button
              className='hover:bg-sw-dark-blue btn bg-light-purple items-center rounded-3xl w-20 md:w-28 h-12'
              type="button"
              onClick={() => {
                const cb = () => this.props.setQuestionNumber(questionNumber - 1);
                this.props.goBackwards(instanceId, cb);
              }}
            >
              <p className='small-text'>Back</p>
            </button>}
          </div>
          <p className="survey-question flex-1">
            Question #{questionNumber}
          </p>
          <div className='w-56'></div>
        </div>
        <Question
          questionId={questionId}
          question={question}
        />
        <Answer
          answers={answers}
          questionId={questionId}
          onOpenTextChange={(text) => this.setState({ openAnswer: text })}
          type={type}
          onQuestionAnswered={(value) => {
            const cb = () => this.props.setQuestionNumber(questionNumber + 1);
            this.props.setAnswerId(questionId, value, instanceId, openAnswer, cb);
          }}
          setAnswer={(value, setCheck) => {
            const newAnswers = !setCheck ? [...multipleAnswers, value] : multipleAnswers.filter(b => b !== value);
            this.setState({ multipleAnswers: newAnswers, multiple: true });
          }}
          onMultipleChoiceFinished={() => {
            const cb = () => this.props.setQuestionNumber(questionNumber + 1);
            this.props.setAnswerId(questionId, sendAnswer, instanceId, openAnswer, cb);
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ question }) => {
  const {
    questions,
    complete,
    child,
    instructions,
    questionNumber,
    researchInstructions
  } = question;
  return {
    instructions,
    questions,
    complete,
    questionNumber,
    researchInstructions,
    child
  };
};

export default connect(mapStateToProps, {
  setAnswerId,
  resetQuestionNumber,
  resetOnLogin,
  setQuestionNumber,
  goBackwards
})(Survey);