import React from 'react';
import Button from '../../common/Button';
import Disclaimer from '../Disclaimer';
import '../../assets/scss/_survey.scss';

const ThankYou = ({ disclaimerVisible, visibleFunction, submitButton, onBackClick }) => {
  const disclaimerComp = disclaimerVisible && <Disclaimer
    visible
    memeInfo="meme4"
    memes
    parentGuardian="child"
    visibleFunction={visibleFunction}
  />;

  return (
    <div className="thank-you-container">
      {disclaimerComp}
      {/* <div className='w-full pl-4 lg:pl-6'>
        <button
          className='hover:bg-sw-dark-blue btn bg-light-purple items-center rounded-3xl w-20 md:w-28 h-12'
          type="button"
          onClick={onBackClick}
        >
          <p className='small-text'>Back</p>
        </button>
      </div> */}
      <div className="thank-you-container">
        <p className="thank-you-text">
          Thank you for taking this survey!
        </p>
        <p className="thank-you-text">
          You may click Submit and close the browser window.
        </p>
        <div className="submit-btn-padding">
          <Button
            disabled={false}
            buttonText="Submit"
            onClick={submitButton}
          />
        </div>
      </div>
    </div>
  )
}

export default ThankYou;
