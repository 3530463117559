import React from 'react';
import '../assets/scss/_common.scss';

const Button = ({
  disabled,
  extraBtnStyle,
  extraPrimaryStyle,
  extraPrimaryTextStyle,
  extraDisabledBtnStyle,
  onClick,
  buttonText
}) => {
  if (disabled === false) {
    return (
      <div className={extraPrimaryStyle}>
        <button
          className={`${extraBtnStyle} button`}
          type="button"
          onClick={() => onClick()}
        >
          <p className={`text ${extraPrimaryTextStyle}`}>
            {buttonText}
          </p>
        </button>
      </div>
    )
  }
  return (
    <div className={extraPrimaryStyle}>
      <button
        type="button"
        className={`${extraDisabledBtnStyle} button disabled`}
        disabled={disabled}
        onClick={() => onClick()}
      >
        <p className={`text-disabled text ${extraPrimaryTextStyle}`}>
          {buttonText}
        </p>
      </button>
    </div>
  );
}

export default Button;
