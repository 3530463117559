import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generateTreatmentOnePage, treatment } from './helperFunctions'
import { generateSatContent, generateTxContent } from './helperFunctions'

const TreatmentCard = ({ isTx, decidingValue }) => {
  let content;
  if (isTx) {
    content = generateTxContent(decidingValue);
  } else {
    content = generateSatContent(decidingValue);
  }

  const { colorClasses, textcolorClasses, text, iconName } = content;

  const iconOrEmpty = () => {
    if (iconName == null) return null;

    return (
      <div className="flex mt-5 justify-center">
        <FontAwesomeIcon icon={iconName} className={`text-5xl ${textcolorClasses}`} />
      </div>
    );
  }

  return (
    <div className="alert-card flex w-full">
      <div className={`alert-card flex justify-center items-center rounded w-full py-2 ${colorClasses}`}>
        <div>
          {iconOrEmpty()}
          <p className={`text-s font-bold leading-loose text-center px-5 pt-5 pb-2 ${textcolorClasses}`}>
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}


const TreatmentCardsRow = ({ advanceReport }) => {
  // Options for these are "No", "Yes", or "N/A"
  const txData = treatment(advanceReport)

  const onePageText = generateTreatmentOnePage(txData)
  const { childTx, childSat, guardianTx, guardianSat } = txData;
  return (
    <>
      <div className="treatment-cards-row container">
        <div className="flex flex-col justify-between md:flex-row md:space-x-3  mt-6 mb-12 space-y-5 md:space-y-0 ">
          <div className="md:w-1/2">
            <div className="text-xs font-bold mb-2">
              ADOLESCENT REPORTS
            </div>
            <div className="flex space-x-3 text-xs h-48">
              <TreatmentCard isTx={true} decidingValue={childTx} />
              <TreatmentCard isTx={false} decidingValue={childSat} />
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="text-xs font-bold mb-2 ">
              PARENT REPORTS
            </div>
            <div className="flex space-x-3 text-xs h-48">
              <TreatmentCard isTx={true} decidingValue={guardianTx} />
              <TreatmentCard isTx={false} decidingValue={guardianSat} />
            </div>
          </div>
        </div>
      </div>
      <p className="treatment-cards-one-page container pb-4 text-xs">
        {onePageText}
      </p>
    </>
  )
}
export default TreatmentCardsRow
