import React from 'react';
import { gadNotes } from '../tableNotes'

const AnxietyRow = ({ advanceReport }) => {
  const { child, guardian } = advanceReport.results

  const guardianAnswer = !!guardian.gad ? `GAD-7 total = ${guardian.gad.replace(/\./g, '')} Anxiety` : 'N/A'

  var childAnswer = 'N/A'
  if (child.gad && child.gad !== 'No answer given') {
    childAnswer = `GAD-7 = ${child.gad.replace(/\./g, '')} Anxiety`
  }

  return (
    <div className="table-row border-t-2 border-b-2 border-sw-lightest-gray align-top py-10" role="row">
      <div className="print-symptom table-cell font-bold text-lg text-sw-dark-blue py-3 pr-2" role="rowheader">
        Anxiety
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {childAnswer}
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {guardianAnswer}
      </div>
      <div className="summary-notes-col table-cell align-top" role="gridcell">
        {gadNotes(advanceReport)}
      </div>
    </div>
  )
}

export default AnxietyRow