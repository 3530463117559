export const url = process.env.REACT_APP_API_HOST;

export const colors = {
  grey: '#D5D1C8',
  lightGrey: '#F2F1EE',
  darkGrey: '#9A927B',
  lightPurple: '#AD77C2',
  white: '#F2F1EE',
  offWhite: '#FBFBFB',
  darkPurple: '#623BBC',
  black: '#231F20',
  orange: '#EFAC0B',
  green: '#56BE42',
  red: '#EF540B'
};

export const FULL = { text: 'Full Report', classValue: 'full-print' };
export const SUMMARY = { text: 'Results Summary', classValue: 'summary-print' };
export const HANDOUT = { text: 'Patient Handout', classValue: 'handout-print' };
export const ONEPAGE = { text: 'One Page Summary', classValue: 'onepage-print' };
export const SUMMARY_AND_PREFERENCES = { text: 'Summary and Preferences', classValue: 'summary-and-preferences-print' };

export const reports = [
  {
    patientId: 0,
    firstName: 'Sarah',
    lastName: 'Peterson',
    birthday: '4/8/2004',
    phqScore: '6, mild depression',
    kcatScore: 'Low category',
    questions: [
      {
        question: 'Little interest or pleasure in doing things?',
        answer: '1, Several Days'
      },
      {
        question: 'Feeling down, depressed, irritable or hopeless?',
        answer: '1, Several Days'
      },
      {
        question: 'Trouble falling asleep, staying asleep, or sleeping too much?',
        answer: '1, Several Days'
      },
      {
        question: 'Poor appetite, weight loss, or over-eating',
        answer: '0, Not At All'
      },
      {
        question: 'Feeling tired, or having little energy?',
        answer: '2, More than Half the Days'
      },
      {
        question: 'Feeling bad about him/herself - feeling like a failure, or that he/she has let him/herself or the family down?',
        answer: '1, Several Days'
      },
      {
        question: 'Trouble concentrating on things like school work, reading, or watching TV?',
        answer: '0, Not At All'
      },
      {
        question: 'Moving or speaking so slowly that other people could have noticied? OR THE OPPOSITE, being so fidgety or restless that he/she is moving around a lot more than usual?',
        answer: '0, Not At All'
      },
      {
        question: 'Thoughts that he/she would be better off dead, or of hurting him/herself in some way?',
        answer: '0, Not At All'
      },
      {
        question: 'In the past year have you felt depressed or sad most days, even if you felt okay sometimes?',
        answer: 'No'
      },
      {
        question: 'If you experiencing any of the problems on this form, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
        answer: 'Not at all difficult'
      }
    ]
  }
];
