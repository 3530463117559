import React from 'react';

const SummaryTableHeader = () => {
  return (
    <div className="header-group-print table-header-group summary-table-header text-xs text-sw-dark-blue font-bold" role="row">
      {/* <tr> */}
        <div className="table-cell pb-2 pr-3" role="columnheader">
          SYMPTOMS
        </div>
        <div className="table-cell pb-2 pr-3" role="columnheader">
          ADOLESCENT REPORT
       </div>
        <div className="table-cell pb-2 pr-3" role="columnheader">
          PARENT REPORT-ADOLESCENT
        </div>
        <div className="summary-notes-col table-cell pb-2 pr-3" role="columnheader">
          NOTES
        </div>
      {/* </tr> */}
    </div>
  )
}
export default SummaryTableHeader