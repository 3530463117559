import React from 'react'
import moment from 'moment';
import PrintReportMenu from './PrintReportMenu'
import { url } from '../../../common/Variables'

const ReportNavHeaders = ({ selectedIdx, onSectionClick, printClass }) => {
  const headers = [
    {text: 'SUMMARY', linkTo: '#summary'},
    {text: 'READINESS, PREFERENCE, BARRIERS', linkTo: '#readiness-preferences-barriers'},
    {text: 'ITEM LEVEL REPORT', linkTo: '#item-level-report'},
    {text: 'CLINICAL CARE RESOURCES', linkTo: '#clinical-care-resources'},
    {text: 'PATIENT HANDOUT', linkTo: '#patient-handout'}
  ]

  return headers.map((header, i) => {
    const selectedClass = selectedIdx === i ? "text-sw-primary-blue underline" : "text-sw-light-gray"
    if (printClass === '') {
      return (
        <li key={header.text}>
          <div
            className={`p-2 nav-headers font-bold text-xs mr-2 ${selectedClass} cursor-pointer hover:text-black`}
            onClick={() => onSectionClick(i)}
          >
            {header.text}
          </div>
        </li>
      )
    }

    return (
      <li key={header.text} className="w-1/5">
        <a
          className="p-2 nav-headers font-bold text-xs mr-2 text-sw-light-gray cursor-pointer hover:text-black"
          onClick={() => onSectionClick(i)}
          href={header.linkTo}
        >
          {header.text}
        </a>
      </li>
    )
  })
}

const StickyHeader = ({
  firstName,
  lastName,
  birthday,
  lastAnswerDate,
  selectedIdx,
  onSectionClick,
  onItemClick,
  printClass
}) => {

  const dob = moment(birthday).format('MM-DD-YYYY')
  var reportDate = "N/A"
  if (lastAnswerDate) reportDate = new Date(lastAnswerDate).toDateString()
  
  return (
    <header className="sticky-header border-b-2 filter drop-shadow-md bg-white pb-1 fixed w-screen top-0 z-10">
      <div>
        <div className="nav-header bg-sw-light-blue sm:py-2">
          <div className="container items-center flex justify-between">
            <div className="text-xs font-bold">
              <a href={`${url}/participants`} target="_blank" rel="noopener noreferrer">
                PARTICIPANTS
              </a>
              &nbsp;&gt; PATIENT REPORT
            </div>
            <div className="text-3xl font-bold text-sw-light-gray">
              CONFIDENTIAL
            </div>
          </div>
        </div>
      </div>
      <nav className="container">
        <div className="flex items-center justify-between my-4 sm:my-6">
          <div>
            <div className="text-xs font-bold">
              PATIENT INFORMATION
            </div>
            <div className="patient-identifiers flex flex-wrap text-xl text-dark-blue font-bold leading-relaxed">
              <span className="print-name">
                {firstName}, {lastName}&nbsp; | &nbsp;
              </span>
              <span className="print-dob mr-2">
                DOB: {dob}
              </span>
            </div>
            <div className="text-xs">
              Survey Date: {reportDate}
            </div>
          </div>
          <div className="one-page-confidential text-3xl font-bold text-sw-light-gray">
            CONFIDENTIAL
          </div>
          <PrintReportMenu onItemClick={onItemClick} />
        </div>
        <div className="one-page-desc text-left text-xs">
          {`This adolescent is participating in iCHART, one of the ETUDES Center studies.
           As part of iCHART, the adolescent and parent completed a brief screening tool
           which assesses common comorbidities for depression that may inform your treatment
           recommendations. The tool also asks about treatment readiness, preferences, and
           barriers that may help you consider next steps. To learn more about the ETUDES Center,
           please visit site at: www.etudescenter.org.`}
        </div>
        <ul className="report-nav-headers flex justify-between">
          <ReportNavHeaders
            selectedIdx={selectedIdx}
            onSectionClick={onSectionClick}
            printClass={printClass}
          />
        </ul>
      </nav>
    </header>
  )
}
export default StickyHeader;
