import React from 'react';
import { get } from '../../../util/helperFunctions'
import ChildGad from './ChildItemLevelSections/ChildGad';
import ChildSbirt from './ChildItemLevelSections/ChildSbirt';
import ChildMania from './ChildItemLevelSections/ChildMania';
import ChildCrafft from './ChildItemLevelSections/ChildCrafft';

const ChildItemLevelReport = ({
  reportDetails,
  crafftDetails,
  sbirtDetails,
  cmrsDetails,
  gadDetails
}) => {

  if (reportDetails.questions.child.length === 0) return null

  // The get() function allows us to dig through the object for an answer and default to an empty string.
  // Per the above TODO, we might want to default to something else like null and allow smaller components
  // to run a check and render some default information if necessary
  const gad = get(['results', 'child', 'gad'], reportDetails, '')
  const cmrs = get(['results', 'child', 'cmrs'], reportDetails, '')
  const sbirt = get(['results', 'child', 'sbirt'], reportDetails, '')
  const mania = get(['results', 'child', 'mania'], reportDetails, '')

  return (
    <div className="child-item-level-report container">

      {/* GAD */}
      <ChildGad gad={gad} gadDetails={gadDetails} />

      {/* MANIA */}
      <ChildMania
        reportDetails={reportDetails}
        cmrsDetails={cmrsDetails}
        cmrs={cmrs}
        mania={mania}
      />

      {/* SUBSTANCE USE */}
      <ChildSbirt sbirt={sbirt} sbirtDetails={sbirtDetails} />

      {/* CRAFFT */}
      <ChildCrafft reportDetails={reportDetails} crafftDetails={crafftDetails} />
    </div>
  )
}

export default ChildItemLevelReport
